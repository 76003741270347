<template lang="html">
  <div>
    <v-container pa-0 fluid>
      <v-row align="center" justify="space-between" class="font-weight-black Barra_Breadcrumbs">
        <v-col cols="12">
          <v-breadcrumbs :items="breadcrumbs" divider="/">
            <v-breadcrumbs-item
              slot="item"
              slot-scope="{ item }"
              :key="item.nivel"
              :disabled="item.disabled"
              @click.native="navegarBreadcrumbs(item)">
                {{ item.text }}
            </v-breadcrumbs-item>
          </v-breadcrumbs>
        </v-col>
      </v-row>
    </v-container>

    <v-tabs
      centered
      class="NotasTabs"
      :grow="isDetalhadoAtivo"
      v-model="tabSelecionada">

      <v-tab href="#tab-consolidado" v-if="!isInconsistencia">
        {{ $tc('label.consolidado', 2) }}
      </v-tab>
      <v-tab href="#tab-detalhado" v-if="!isInconsistencia" :disabled="!tituloNotaSelecionada">
        {{ $t('label.detalhado') }} {{ tituloNotaSelecionada ? '- ' + tituloNotaSelecionada : '' }}
      </v-tab>

      <v-tab href="#tab-sellin" v-if="isInconsistencia">
        {{ $t('label.sellin') }}
      </v-tab>
      <v-tabs-items v-model="tabSelecionada">
        <v-tab-item id="tab-consolidado" v-if="!isInconsistencia">
          <notas-lista-consolidado
            ref="listaConsolidado"
            :visao="visao"
            :key="visao"
            v-show="tabSelecionada === 'tab-consolidado'"
            @Notas__SelecionaItemNivel2="selectItem"></notas-lista-consolidado>
        </v-tab-item>

        <v-tab-item id="tab-detalhado" v-if="!isInconsistencia">
          <notas-lista-detalhado
            ref="listaDetalhado"
            :visao="visao"
            :notaSelecionada="notaSelecionada"
            v-show="isDetalhadoAtivo"></notas-lista-detalhado>
        </v-tab-item>

        <v-tab-item id="tab-sellin" v-if="isInconsistencia">
          <notas-lista-inconsistencia
            visao="SELLIN"
            v-show="tabSelecionada === 'tab-sellin'"></notas-lista-inconsistencia>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex';
import { generateComputed } from '../../produto/common/functions/roles-computed-generator';
import notasListaConsolidado from './NotasNivel2DadosListaConsolidado';
import notasListaDetalhado from './NotasNivel2DadosListaDetalhado';
import notasListaInconsistencia from './NotasNivel2DadosInconsistenciaAgrupado';

export default {
  name: 'Notas',
  data() {
    return {
      tabSelecionada: 'tab-consolidado',
      visao: '',
      notaSelecionada: {},
      breadcrumbs: [],
      tituloNotaSelecionada: null,
    };
  },
  watch: {
    tabSelecionada: {
      handler() {
        if (this.tabSelecionada !== 'tab-detalhado') {
          this.tituloNotaSelecionada = null;
          if (this.breadcrumbs.length === 3) {
            this.breadcrumbs.splice(2, 1);
          }
        }
      },
    },
  },
  computed: {
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    ...generateComputed('NOTA', [
      'canAccessPage',
      'canAccessCRUD',
    ]),

    isSellIn() {
      return this.visao === 'SELLIN';
    },
    isDetalhadoAtivo() {
      return this.tabSelecionada === 'tab-detalhado';
    },

    isInconsistencia() {
      return this.visao === 'INCONSISTENCIA';
    },
  },
  components: {
    notasListaConsolidado,
    notasListaDetalhado,
    notasListaInconsistencia,
  },
  methods: {
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast('Acesso negado');
      }
    },
    navegarBreadcrumbs(item) {
      if (item.disabled) {
        return;
      }
      this.breadcrumbs.splice(1, 1);
      this.$router.push({ name: item.to });
    },
    selectItem(item) {
      this.adicionarItemBreadcrumbs(item);
      this.notaSelecionada = item;
      this.tabSelecionada = 'tab-detalhado';
    },
    adicionarItemBreadcrumbs(item) {
      if (this.breadcrumbs.length === 3) {
        this.breadcrumbs.splice(2, 1);
      }
      let caminhoItem = '';

      if (this.indModoVarejo) {
        if (this.isSellIn) {
          caminhoItem = item.competencia.concat(' :: ').concat(item.cod_fornecedor).concat(' - ').concat(item.nom_fornecedor);
        }
      } else {
        caminhoItem = item.competencia.concat(' :: ').concat(item.cod_cliente).concat(' - ').concat(item.nom_cliente);
      }

      this.tituloNotaSelecionada = caminhoItem;
      this.breadcrumbs.push({
        text: caminhoItem,
        value: item,
        to: 'nivel2Notas',
        disabled: true,
        nivel: 3,
      });
    },
  },
  beforeMount() {
    if (!this.$route.params.visao) {
      this.$router.push({ name: 'notas' });
    } else {
      this.visao = this.$route.params.visao;
      this.breadcrumbs = this.$route.params.breadcrumbsParam;
      this.breadcrumbs[1].disabled = true;
    }
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
    }
    if (this.isInconsistencia) {
      this.tabSelecionada = 'tab-sellin';
    }
  },
};
</script>

<style lang="scss">
  .Barra_Breadcrumbs {
    .v-breadcrumbs {
      padding: 0.5em 0 1em 0;
    }
    .v-breadcrumbs__item {
      font-size: 1.2em;
      cursor: pointer;
    }
    .v-breadcrumbs__item--disabled {
      cursor: default
    }
    ul:not(.md-list)>li+li {
      margin-top: 0 !important;
    }
  }

  .NotasTabs .theme--light.v-expansion-panel--focusable .v-expansion-panel__container:focus {
    background-color: white;
  }

  .NotasTabs .v-tabs__div {
    max-width: 100%;
  }

  .NotasTabs .v-tabs__bar {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12)!important;
  }

  .NotasTabs .v-tabs__slider {
    height: 3px;
  }
</style>
