<template>
  <div>
    <v-container fluid px-0 py-2 class='Container_Nivel2_Notas'>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-text-field
            v-model="pesquisaTabela"
            append-icon="search"
            @input="filtrar"
            :label="`${$tc('label.pesquisar', 1)}`"
            class="Barra_Pesquisa mx-4"
            single-line
            hide-details
            clearable
          ></v-text-field>
        </v-col>

        <v-container fluid grid-list-md class="Container_Tabela py-3">
          <v-row>
            <v-col cols="12">
              <v-data-table
                id="tabela_notas"
                :headers="headers"
                :items="listaNotasConsolidado"
                :options.sync="pagination"
                :server-items-length="totalPage"
                :no-data-text="$t('label.tabela_sem_conteudo')"
                :footer-props="{
                  itemsPerPageOptions: [10, 25, 50],
                }">
                <template v-slot:item.competencia="{ item }">
                  {{ `${item.competencia.substring(4)}/${item.competencia.substring(0,4)}` }}
                </template>
                <template v-slot:item.qtd_item="{ item }">
                  {{ getNumber(item.qtd_item) }}
                </template>
                <template v-slot:item.valor_bruto="{ item }">
                  {{ getMoney(item.valor_bruto) }}
                </template>
                <template v-slot:item.valor_liquido="{ item }">
                  {{ getMoney(item.valor_liquido) }}
                </template>
                <template v-slot:item.acoes="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn icon class="mx-0" v-on="on" @click="detalharConsolidado(item)">
                        <v-icon class="fa-sm">arrow_forward</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('label.detalhado') }}</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {
  getMoney,
  getNumber,
} from '../../produto/common/functions/helpers';

export default {
  name: 'NotasNivel2DadosListaConsolidado',
  components: {
  },
  props: {
    visao: String,
  },
  data() {
    return {
      resources: this.$api.notas(this.$resource),
      pesquisaTabela: '',
      esperarDigitar: false,
      listaNotasConsolidado: [],
      rowsPerPageItems: [10, 20, 30, 40, 50],
      pagination: {
        rowsPerPage: 10,
        page: 1,
        descending: true,
      },
      totalPage: 0,

      headers: [
        { text: this.$tc('label.quantidade_produto', 1), value: 'qtd_item', sortable: true },
        { text: this.$tc('label.valor_bruto', 1), value: 'valor_bruto', sortable: true },
        { text: this.$tc('label.valor_liquido', 1), value: 'valor_liquido', sortable: true },
        {
          text: '', value: 'acoes', sortable: false, width: '2%', align: 'center',
        },
      ],
      mapaColunasOrdenacao: {
        competencia: 'competencia',
        qtdItem: 'qtd_item',
        valorBruto: 'valor_bruto',
        valorLiquido: 'valor_liquido',
        codFornecedor: 'cod_fornecedor',
        nomeFornecedor: 'nom_fornecedor',
        codCliente: 'cod_cliente',
        nomeCliente: 'nom_cliente',
      },
    };
  },
  watch: {
    pagination: {
      handler() {
        this.buscar();
      },
      deep: true,
    },
    pesquisaTabela() {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.filtrar();
      }, 500);
    },
  },
  computed: {
    isSellIn() {
      return this.visao === 'SELLIN';
    },

    isDevolucao() {
      return this.visao === 'DEVOLUCAO';
    },

    isSellOut() {
      return this.visao === 'SELLOUT';
    },
  },
  methods: {
    getMoney,
    getNumber,

    filtrar() {
      if (this.esperarDigitar) return;
      this.esperarDigitar = true;
      setTimeout(() => {
        this.esperarDigitar = false;
        this.buscar();
      }, 5E2);
    },

    buscar() {
      const params = {
        filtro: this.pesquisaTabela,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
      };

      let promise = null;

      if (this.isSellIn) {
        promise = this.resources.listarNotasSellInConsolidado(params);
      } else if (this.isDevolucao) {
        promise = this.resources.listarNotasDevolucaoConsolidado(params);
      } else {
        promise = this.resources.listarNotasSellOutConsolidado(params);
      }

      promise.then((response) => {
        this.listaNotasConsolidado = response.data.resposta;
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.pararEsperar();
      }, (err) => {
        this.pararEsperar();
        this.$error(this, err);
      });
    },

    pararEsperar() {
      setTimeout(() => {
        this.esperarDigitar = false;
      }, 2E2);
    },

    detalharConsolidado(item) {
      this.$emit('Notas__SelecionaItemNivel2', item);
    },

    addColunasSellIn() {
      this.headers.unshift(
        { text: this.$tc('label.competencia_sellin_consolidado', 1), value: 'competencia', sortable: true },
        { text: this.$tc('label.cod_cliente', 1), value: 'cod_cliente', sortable: true },
        { text: this.$tc('label.nome_cliente', 1), value: 'nom_cliente', sortable: true },
        { text: this.$tc('label.regiao_uf', 1), value: 'regiao_uf', sortable: true },
      );
    },

    addColunasDevolucao() {
      this.headers.unshift(
        { text: this.$tc('label.competencia_sellin_consolidado', 1), value: 'competencia', sortable: true },
        { text: this.$tc('label.cod_cliente', 1), value: 'cod_cliente', sortable: true },
        { text: this.$tc('label.nome_cliente', 1), value: 'nom_cliente', sortable: true },
        { text: this.$tc('label.regiao_uf', 1), value: 'regiao_uf', sortable: true },
      );
    },

    addColunasSellOut() {
      this.headers.unshift(
        { text: this.$tc('label.competencia_sellin_consolidado', 1), value: 'competencia', sortable: true },
      );
    },
  },
  beforeMount() {
  },
  mounted() {
    if (this.visao != null && this.visao.length) {
      if (this.isSellIn) {
        this.addColunasSellIn();
      } else if (this.isDevolucao) {
        this.addColunasDevolucao();
      } else {
        this.addColunasSellOut();
      }
      this.buscar();
    }
  },
};
</script>

<style>
.Container_Tabela table.v-table tbody td:first-child,.Container_Tabela  table.v-table tbody td:not(:first-child),
  .Container_Tabela table.v-table tbody th:first-child, .Container_Tabela table.v-table tbody th:not(:first-child),
  .Container_Tabela table.v-table thead td:first-child, .Container_Tabela table.v-table thead td:not(:first-child),
  .Container_Tabela table.v-table thead th:first-child, .Container_Tabela table.v-table thead th:not(:first-child) {
  padding: 0 11px;
}
.Container_Nivel2_Notas .Barra_Pesquisa {
  width: 33%;
}
</style>
