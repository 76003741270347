<template>
  <div>
    <v-container fluid px-0 py-2 class='Container_Tabela'>
      <v-row align="center" justify="center">
        <v-container fluid grid-list-md class="Container_Tabela py-3">
          <v-row>
            <v-col cols="12">
            <v-data-table
              id="tabela_notas"
              :headers="headers"
              :items="listaNotasInconsistencia"
              :options.sync="pagination"
              :server-items-length="totalPage"
              :no-data-text="$t('label.tabela_sem_conteudo')"
              :footer-props="{
                 itemsPerPageOptions: [10, 20, 30, 40, 50],
                }"
              >
              <template v-slot:item.acoes="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon
                      v-on="on"
                      @click.stop="openReprocessarDialog(item)">
                      <v-icon color="green">cached</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $tc('label.reprocessar', 1) }}</span>
                </v-tooltip>
              </template>

              <template v-slot:item.valor="{ item }">
                <v-menu
                  :key="item.id"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  origin="top right"
                  offset-x
                  left>
                  <template v-slot:activator="{ on }">
                    <v-btn text icon v-on="on">
                      <v-icon>attach_money</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-list two-line class="pa-0">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>{{ $t('label.quantidade_produto') }}</v-list-item-title>
                            <v-list-item-subtitle>{{ item.qtd_produto }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider />
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>{{ $t('label.valor_bruto') }}</v-list-item-title>
                            <v-list-item-subtitle>
                              {{ getMoney(item.valor_bruto) }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider />
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>{{ $t('label.valor_liquido') }}</v-list-item-title>
                            <v-list-item-subtitle>
                              {{ getMoney(item.valor_liquido) }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider />
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>{{ $t('label.ipi') }}</v-list-item-title>
                            <v-list-item-subtitle>
                              {{ getMoney(item.valor_ipi) }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider />
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>{{ $t('label.icms') }}</v-list-item-title>
                            <v-list-item-subtitle>
                              {{ getMoney(item.valor_icms) }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider />
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>{{ $t('label.pis_cofins') }}</v-list-item-title>
                            <v-list-item-subtitle>
                              {{ getMoney(item.valor_pis_cofins) }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </template>
              <template v-slot:item.erro="{ item }">
                <v-menu
                  :key="item.id + 99"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  origin="top right"
                  offset-x
                  left>
                  <template v-slot:activator="{ on }">
                    <v-btn text icon v-on="on">
                      <v-icon>info</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-list class="pa-0">
                        <v-list-item v-for="(item, index) in item.erros.value.slice(1, -1).split(', ')" :key="index">
                          <v-list-item-content>
                            <v-list-item-subtitle>{{$tc('label.erro', 1)}} {{ index + 1 }}</v-list-item-subtitle>
                            <v-list-item-title>{{ item.slice(1, -1) }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider />
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </template>
            </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
    </v-container>
    <confirm
      ref="reprocessarDialog"
      :message="dialog.content"
      :persistent="true"
      @agree="reprocessarInconsistencia()">
    </confirm>
  </div>
</template>

<script>
import {
  getMoney,
} from '../../produto/common/functions/helpers';
import Confirm from '../../produto/shared-components/vuetify/dialog/Confirm';

export default {
  name: 'NotasListaInconsistenciaDetalhado',
  components: {
    Confirm,
  },
  props: {
    lote: Object,
    tipoErro: String,
    pesquisaTabela: String,
  },
  data() {
    return {
      resourcesInconsistencia: this.$api.notas(this.$resource),
      resourcesReprocessamento: this.$api.notas(this.$resource),
      menu: false,
      listaNotasInconsistencia: [],
      totalPage: 0,
      inconsistenciaSelecionada: {},
      esperarDigitar: false,
      pagination: {
        rowsPerPage: 10,
        page: 1,
        sortBy: ['data_emissao'],
        descending: true,
      },
      dialog: {
        from: '',
        title: this.$t('label.atencao'),
        content: '',
        action() {},
      },
    };
  },
  watch: {
    pagination: {
      handler() {
        this.buscar();
      },
      deep: true,
    },
  },
  computed: {
    headers() {
      const headers = [];

      headers.push({
        text: this.$tc('label.acoes', 2), value: 'acoes', sortable: false, width: '1%', align: 'center',
      });
      headers.push({ text: this.$tc('label.data_emissao_lista_detalhado', 1), value: 'data_emissao', sortable: true });
      headers.push({ text: this.$tc('label.numero_nota_fiscal', 1), value: 'numero_nota_fiscal', sortable: true });
      headers.push({ text: this.$tc('label.cod_cliente', 1), value: 'cod_cliente', sortable: true });
      headers.push({ text: this.$tc('label.nome_cliente', 1), value: 'nome_cliente', sortable: true });
      headers.push({ text: this.$tc('label.cod_produto', 1), value: 'cod_produto', sortable: true });
      headers.push({ text: this.$tc('label.nome_produto', 1), value: 'nome_produto', sortable: true });
      headers.push({ text: this.$tc('label.unidade_medida', 1), value: 'unidade_medida', sortable: true });
      headers.push({
        text: this.$tc('label.valor', 2), value: 'valor', sortable: false, width: '2%', align: 'center',
      });
      headers.push({
        text: this.$tc('label.erro', 2), value: 'erro', sortable: false, width: '2%', align: 'center',
      });

      return headers;
    },
    isSellIn() {
      return this.tipoErro === 'SELLIN';
    },

    isDevolucao() {
      return this.tipoErro === 'DEVOLUCAO';
    },

    isSellOut() {
      return this.tipoErro === 'SELLOUT';
    },
  },
  methods: {
    getMoney,

    filtrar() {
      if (this.esperarDigitar) return;
      this.esperarDigitar = true;
      setTimeout(() => {
        this.esperarDigitar = false;
        this.buscar();
      }, 5E2);
    },

    buscar() {
      const params = {
        filtro: this.pesquisaTabela,
        tipoErro: this.tipoErro,
        numeroLote: this.lote.lote,
        dataRemessa: this.lote.data_remessa,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
      };

      this.resourcesInconsistencia.listarNotasInconsistenciaDetalhado(params).then((response) => {
        this.listaNotasInconsistencia = response.data.resposta;
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.$emit('TotalInconsistenciasPorLote', this.lote, response.data.length);
      }, (err) => {
        this.$error(this, err);
      });
    },

    reprocessarInconsistencia() {
      const inconsistencia = this.inconsistenciaSelecionada;

      const params = {
        tipoErro: this.tipoErro,
        numeroLote: this.lote.lote,
        idInconsistencia: inconsistencia.id,
      };

      this.resourcesReprocessamento.reprocessarInconsistencia(params).then((response) => {
        this.$emit('InconsistenciaReprocessada', response);
      })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },

    openReprocessarDialog(ref, from) {
      this.inconsistenciaSelecionada = ref;
      this.dialog.content = this.$t('message.inconsistencia_reprocessar');
      this.dialog.action = this.reprocessarInconsistencia;
      this.dialog.from = from;
      setTimeout(() => this.$refs.reprocessarDialog.open());
    },

    pararEsperar() {
      setTimeout(() => {
        this.esperarDigitar = false;
      }, 2E2);
    },

    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },

  },
  mounted() {
    if (this.tipoErro != null && this.tipoErro.length) {
      if (this.isSellOut) {
        this.headers.splice(3, 2);
      }
      this.buscar();
    }
  },
};
</script>

<style>
.Container_Tabela table.v-table tbody td:first-child,.Container_Tabela  table.v-table tbody td:not(:first-child),
  .Container_Tabela table.v-table tbody th:first-child, .Container_Tabela table.v-table tbody th:not(:first-child),
  .Container_Tabela table.v-table thead td:first-child, .Container_Tabela table.v-table thead td:not(:first-child),
  .Container_Tabela table.v-table thead th:first-child, .Container_Tabela table.v-table thead th:not(:first-child) {
  padding: 0 11px;
}
.Barra_Pesquisa {
  width: 33%;
}
</style>
