<template>
  <div>
    <v-container fluid px-0 py-2 class='Container_Tabela'>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-text-field
            v-model="pesquisaTabela"
            append-icon="search"
            @input="filtrar"
            :label="`${$tc('label.pesquisar', 1)}`"
            class="Barra_Pesquisa mx-4"
            single-line
            hide-details
            clearable
          ></v-text-field>
        </v-col>

        <v-container fluid grid-list-md class="Container_Tabela py-3">
          <v-row>
            <v-col cols="12">
              <v-data-table
                id="tabela_notas"
                :headers="headers"
                :items="listaNotasDetalhado"
                :options.sync="pagination"
                :server-items-length="totalPage"
                :no-data-text="$t('label.tabela_sem_conteudo')"
                :footer-props="{
                  itemsPerPageOptions: [5, 10, 25, 50],
                }"
                >
                <template v-slot:item.qtd_item="{ item }">
                  {{ getNumber(item.qtd_item) }}
                </template>
                <template v-slot:item.valor_bruto="{ item }">
                  {{ getMoney(item.valor_bruto) }}
                </template>
                <template v-slot:item.valor_liquido="{ item }">
                  {{ getMoney(item.valor_liquido) }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {
  getMoney,
  getNumber,
} from '../../produto/common/functions/helpers';

export default {
  name: 'NotasNivel2DadosListaDetalhado',
  components: {
  },
  props: {
    visao: String,
    notaSelecionada: Object,
  },
  data() {
    return {
      resources: this.$api.notas(this.$resource),
      pesquisaTabela: '',
      listaNotasDetalhado: [],
      pagination: {
        rowsPerPage: 10,
        page: 1,
        descending: true,
      },
      totalPage: 0,
      esperarDigitar: false,

      headers: [
        {
          text: this.$tc('label.data_emissao_lista_detalhado', 1),
          value: 'data_emissao',
          sortable: true,
        },
        { text: this.$tc('label.numero_nf', 1), value: 'numero_nota_fiscal', sortable: true },
        { text: this.$tc('label.numero_documento_fiscal', 1), value: 'numero_documento_fiscal', sortable: true },
        { text: this.$tc('label.cod_produto', 1), value: 'cod_produto', sortable: true },
        { text: this.$tc('label.nome_produto', 1), value: 'nom_produto', sortable: true },
        { text: this.$tc('label.quantidade_produto', 1), value: 'qtd_item', sortable: true },
        { text: this.$tc('label.valor_bruto', 1), value: 'valor_bruto', sortable: true },
        { text: this.$tc('label.valor_liquido', 1), value: 'valor_liquido', sortable: true },
      ],
    };
  },
  watch: {
    notaSelecionada: {
      handler() {
        this.resetarPaginacao();
        this.buscar();
      },
    },
    pagination: {
      handler() {
        this.buscar();
      },
      deep: true,
    },
    pesquisaTabela() {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.filtrar();
      }, 500);
    },
  },
  computed: {
    isSellIn() {
      return this.visao === 'SELLIN';
    },

    isDevolucao() {
      return this.visao === 'DEVOLUCAO';
    },

    isSellOut() {
      return this.visao === 'SELLOUT';
    },
  },
  methods: {
    getMoney,
    getNumber,

    filtrar() {
      if (this.esperarDigitar) return;
      this.esperarDigitar = true;
      setTimeout(() => {
        this.esperarDigitar = false;
        this.buscar();
      }, 5E2);
    },

    buscar() {
      if (!this.notaSelecionada.anoMes && !this.notaSelecionada.ano_mes) {
        return;
      }

      const params = {
        filtro: this.pesquisaTabela,
        idFornecedor: this.notaSelecionada.idFornecedor
          || this.notaSelecionada.id_fornecedor,
        idFilial: this.notaSelecionada.idFilial
          || this.notaSelecionada.id_filial,
        idCliente: this.notaSelecionada.idCliente
          || this.notaSelecionada.id_cliente,
        anoMes: this.notaSelecionada.anoMes
          || this.notaSelecionada.ano_mes,
        codRegiao: this.notaSelecionada.codRegiao,
        codFornecedor: this.notaSelecionada.codFornecedor,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
      };

      let promise = null;
      if (this.isSellIn) {
        promise = this.resources.listarNotasSellInDetalhado(params);
      } else if (this.isDevolucao) {
        promise = this.resources.listarNotasDevolucaoDetalhado(params);
      } else {
        promise = this.resources.listarNotasSellOutDetalhado(params);
      }
      promise.then((response) => {
        this.listaNotasDetalhado = response.data.resposta;
        this.totalPage = response.data.quantidadeRegistrosPagina;
      }, (err) => {
        this.$error(this, err);
      });
    },
    resetarPaginacao() {
      this.totalPage = 0;
      this.pagination.rowsPerPage = 10;
      this.pagination.page = 1;
      this.pagination.descending = true;
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperarDigitar = false;
      }, 2E2);
    },
  },
  beforeMount() {
  },
  mounted() {
    if (this.isSellOut) {
      this.montarColunasVarejo();
    }
    if (this.notaSelecionada.anoMes && this.visao && this.visao.length) {
      this.buscar();
    }
  },
};
</script>

<style>
.Container_Tabela table.v-table tbody td:first-child,.Container_Tabela  table.v-table tbody td:not(:first-child),
  .Container_Tabela table.v-table tbody th:first-child, .Container_Tabela table.v-table tbody th:not(:first-child),
  .Container_Tabela table.v-table thead td:first-child, .Container_Tabela table.v-table thead td:not(:first-child),
  .Container_Tabela table.v-table thead th:first-child, .Container_Tabela table.v-table thead th:not(:first-child) {
  padding: 0 11px;
}
.Barra_Pesquisa {
  width: 33%;
}
</style>
